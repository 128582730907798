.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.about-container img {
  max-height: 50vh;
  max-width: 50vw;
}

.about-container .about-container-title {
  width: 80%;
  height: 20%;
  max-width: 250px;
  max-height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--primary-color-text);
  border-radius: 10px;
  border: 1px solid var(--secondary-color-text);
  opacity: 0;
}

.about-container-title span {
  color: #fff;
  font-weight: bold;
}