.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  background: var(--primary-background);
}

.App .container {
  display: flex;
  flex-direction: column;
  min-width: 50vw;
}

.App .container h2 {
  color: var(--primary-color-text);
}

.App .container input {
  width: 100%;
  height: 35px;
  margin-bottom: 16px;
  box-sizing: border-box;

  font-family: Poppins, sans-serif;
  font-size: 0.75rem;
  text-indent: 1rem;
  color: var(--secondary-color-text);
  border: none;
  border-radius: 8px;
  background-color: var(--secondary-background);
}

.App .container input:focus {
  outline: none;
  border: 1px solid var(--primary-color-text);
}

.App .container button {
  width: 100%;
  height: 35px;
  border-radius: 8px;
  margin-bottom: 16px;

  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  border: none;
  cursor: pointer;
  background: linear-gradient(180deg, #7149c4 -30%, #4a15b9 128%);
}
