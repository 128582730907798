.invalid-viewport-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding: 20px;
  
  background: #0E101C;
}

.invalid-viewport-container p {
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
  
  font-size: 100%;
  font-weight: 500;
  color: #06F62D;
}

.invalid-viewport-container p:last-child {
  font-weight: 700;
}