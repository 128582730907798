@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

:root {
  --primary-background: #0e101c;
  --secondary-background: #20243b;
  --primary-color-text: #7149c3;
  --secondary-color-text: #444965;
  --border-color-avatar: transparent;
}

body,
#root {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
