@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
:root {
  --primary-background: #0e101c;
  --secondary-background: #20243b;
  --primary-color-text: #7149c3;
  --secondary-color-text: #444965;
  --border-color-avatar: transparent;
}

body,
#root {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.about-container img {
  max-height: 50vh;
  max-width: 50vw;
}

.about-container .about-container-title {
  width: 80%;
  height: 20%;
  max-width: 250px;
  max-height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--primary-color-text);
  border-radius: 10px;
  border: 1px solid var(--secondary-color-text);
  opacity: 0;
}

.about-container-title span {
  color: #fff;
  font-weight: bold;
}
.raid-wrapper {
  display: flex;
  justify-content: center;
}

.raid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 62%;
  max-width: 252px;
}

.raid-container .header {
  position: relative;
}

.raid-container .header .avatar {
  height: auto;
  width: 50vw;
  max-width: 200px;

  box-sizing: border-box;
  border: 2px solid red;
}

.raid-container-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 24%;
  max-width: 250px;
  min-width: 224px;
  max-height: 72px;
  padding: 5px;
  position: relative;

  background: var(--primary-color-text);
  border-radius: 10px;
  border: 1px solid var(--secondary-color-text);
  opacity: 0;
}

.raid-container-title .label {
  position: absolute;
  padding: 2px 14px;
  top: -12px;
  left: -20px;

  background: #1a0438;
  transform: rotate(-28deg);
}

.raid-container-title .label span {
  color: #fff;
}

.raid-container-title span {
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.raid-container-title img {
  height: 30px;
  width: auto;
  position: absolute;
  right: 0;
  bottom: -17px;
}
.share-wrapper {
  display: flex;
  justify-content: center;
}

.share-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 62%;
  max-width: 252px;
}

.share-container .header {
  position: relative;
}

.share-container .header .avatar {
  height: auto;
  width: 50vw;
  max-width: 200px;

  box-sizing: border-box;
  border: 4px solid var(--border-color-avatar);
}

.share-container .header .trophy {
  height: 16vh;
  width: auto;
  max-height: 44%;
  min-height: 44%;
  position: absolute;
  right: -23px;
  bottom: 0;

  transform: rotate(30deg);
}

.share-container-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24%;
  max-width: 250px;
  min-width: 224px;
  max-height: 52px;
  padding: 5px;
  flex-wrap: wrap;
  position: relative;

  background: var(--primary-color-text);
  border-radius: 10px;
  border: 1px solid var(--secondary-color-text);
  opacity: 0;
}

.share-container-title span {
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.share-container-title span:nth-child(2) {
  display: none;
}

.share-container-title img {
  height: 30px;
  width: auto;
  position: absolute;
  right: 0;
  bottom: -17px;
}
.invalid-viewport-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding: 20px;
  
  background: #0E101C;
}

.invalid-viewport-container p {
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
  
  font-size: 100%;
  font-weight: 500;
  color: #06F62D;
}

.invalid-viewport-container p:last-child {
  font-weight: 700;
}
.notfound-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;

  background: #0E101C;
}

.notfound-container h1 {
  color: #06F62D;
  font-size: 2rem;
}

 @media (max-width: 200px) {
  .notfound-container h1 {
    font-size: 1rem;
  }
} 
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  background: var(--primary-background);
}

.App .container {
  display: flex;
  flex-direction: column;
  min-width: 50vw;
}

.App .container h2 {
  color: var(--primary-color-text);
}

.App .container input {
  width: 100%;
  height: 35px;
  margin-bottom: 16px;
  box-sizing: border-box;

  font-family: Poppins, sans-serif;
  font-size: 0.75rem;
  text-indent: 1rem;
  color: var(--secondary-color-text);
  border: none;
  border-radius: 8px;
  background-color: var(--secondary-background);
}

.App .container input:focus {
  outline: none;
  border: 1px solid var(--primary-color-text);
}

.App .container button {
  width: 100%;
  height: 35px;
  border-radius: 8px;
  margin-bottom: 16px;

  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  border: none;
  cursor: pointer;
  background: linear-gradient(180deg, #7149c4 -30%, #4a15b9 128%);
}

