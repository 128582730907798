.notfound-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;

  background: #0E101C;
}

.notfound-container h1 {
  color: #06F62D;
  font-size: 2rem;
}

 @media (max-width: 200px) {
  .notfound-container h1 {
    font-size: 1rem;
  }
} 