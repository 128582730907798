.share-wrapper {
  display: flex;
  justify-content: center;
}

.share-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 62%;
  max-width: 252px;
}

.share-container .header {
  position: relative;
}

.share-container .header .avatar {
  height: auto;
  width: 50vw;
  max-width: 200px;

  box-sizing: border-box;
  border: 4px solid var(--border-color-avatar);
}

.share-container .header .trophy {
  height: 16vh;
  width: auto;
  max-height: 44%;
  min-height: 44%;
  position: absolute;
  right: -23px;
  bottom: 0;

  transform: rotate(30deg);
}

.share-container-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24%;
  max-width: 250px;
  min-width: 224px;
  max-height: 52px;
  padding: 5px;
  flex-wrap: wrap;
  position: relative;

  background: var(--primary-color-text);
  border-radius: 10px;
  border: 1px solid var(--secondary-color-text);
  opacity: 0;
}

.share-container-title span {
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.share-container-title span:nth-child(2) {
  display: none;
}

.share-container-title img {
  height: 30px;
  width: auto;
  position: absolute;
  right: 0;
  bottom: -17px;
}